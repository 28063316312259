import {useEffect, useState} from "react";
import Blog from "./components/Blog";

function App() {
  const [name,setName] = useState('')
  const [email,setEmail] = useState('')
  const [phone,setPhone] = useState('')
  const [subject,setSubject] = useState('')
  const [message,setMessage] = useState('')
  const [sendingMsg,setSendingMsg] = useState(false)
  const [respMsg,setRespMsg] = useState('')

  useEffect(()=>{
    const mobileMenuBtn = document.querySelector('.popup-mobile-menu')
    const hamburgerBtn = document.querySelector('#menuBtn')
    const closeHamburgerBtn = document.querySelector('.close-menu-activation')

    hamburgerBtn.addEventListener('click',()=>{
      mobileMenuBtn.classList.add('menu-open')
    })

    closeHamburgerBtn.addEventListener('click',()=>{
      mobileMenuBtn.classList.remove('menu-open')
    })
  },[])

  function contactMe(){
    setSendingMsg(true)
    /*const formData = {
      'your-name':name,
      'your-email':email,
      'your-subject':subject,
      'your-message':message,
      'your-phone':phone
    }*/

    const formData = new FormData()
    formData.append('your-name',name)
    formData.append('your-email',email)
    formData.append('your-subject',subject)
    formData.append('your-message',message)
    formData.append('your-phone',phone)

    fetch('https://www.mike.emmawenani.com/wp-json/contact-form-7/v1/contact-forms/37/feedback',{
      method:'POST',
      body:formData
    }).then(res => res.json())
        .then(data => {
          setRespMsg(data.message)
          setSendingMsg(false)

          //timeout the response message
          setTimeout(()=>{
            setRespMsg('')
          },5000)
        }).catch(err => {
          console.log(err)
    })
  }

  return (
      <>
        <main className="main-page-wrapper spybody" data-spy="scroll" data-target=".navbar-example2" data-offset="150">

          <header className="rn-header haeder-default black-logo-version header--fixed header--sticky">
            <div className="header-wrapper m--0 rn-popup-mobile-menu row align-items-center">
              <div className="col-lg-2 col-6">
                <div className="header-left">
                  <div className="logo">
                    <a href="#">
                      <img src="/assets/images/logo/michael-ayee-logo.png" alt="logo"/>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-10 col-6">
                <div className="header-center">
                  <nav id="sideNav" className="mainmenu-nav navbar-example2 onepagenav">
                    <ul className="primary-menu nav nav-pills">
                      <li className="nav-item current"><a className="nav-link smoth-animation" href="#home">Home</a>
                      </li>
                      <li className="nav-item"><a className="nav-link smoth-animation" href="#about">About Me</a></li>
                      <li className="nav-item"><a className="nav-link smoth-animation" href="#skill">Skills</a></li>
                      <li className="nav-item"><a className="nav-link smoth-animation" href="#service">Services</a>
                      </li>
                      <li className="nav-item"><a className="nav-link smoth-animation" href="#portfolio">Portfolio</a>
                      </li>
                      <li className="nav-item"><a className="nav-link smoth-animation" href="#client">Clients</a></li>
                      <li className="nav-item"><a className="nav-link smoth-animation" href="#blog">blog</a></li>
                    </ul>
                  </nav>
                  <div className="header-right">
                    <a className="rn-btn no-shadow btn-theme smoth-animation"
                       href="#contacts"><span>CONTACT ME</span></a>
                    <i id="menuBtn" className="feather-menu humberger-menu d-block d-xl-none"></i>
                    <div className="close-menu d-block">
                                <span className="closeTrigger">
                                    <i className="fa-light fa-xmark"></i>
                                </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <div className="popup-mobile-menu">
            <div className="inner">
              <div className="menu-top">
                <div className="menu-header">
                  <a className="logo" href="/">
                    <img src="/assets/images/logo/michael-ayee-logo.png" alt="Personal Portfolio"/>
                  </a>
                  <div className="close-button">
                    <button className="close-menu-activation close"><i className="fa-light fa-xmark"></i></button>
                  </div>
                </div>
                <p className="discription">I design beautiful user-centred experiences that achieve business goals. I am results-oriented with a keen eye for captivating, eye-catching creative works.</p>
              </div>
              <div className="content">
                <ul className="primary-menu nav nav-pills onepagenav">
                  <li className="nav-item current"><a className="nav-link smoth-animation" href="#home">Home</a></li>
                  <li className="nav-item"><a className="nav-link smoth-animation" href="#about">About me</a></li>

                  <li className="nav-item"><a className="nav-link smoth-animation" href="#skill">Skills</a></li>

                  <li className="nav-item"><a className="nav-link smoth-animation" href="#service">Services</a></li>

                  <li className="nav-item"><a className="nav-link smoth-animation" href="#portfolio">portfolio</a></li>

                  <li className="nav-item"><a className="nav-link smoth-animation" href="#client">clients</a></li>

                  <li className="nav-item"><a className="nav-link smoth-animation" href="#blog">blog</a></li>

                  <li className="nav-item"><a className="nav-link smoth-animation" href="#contacts">contact me</a></li>
                </ul>
                <div className="social-share-style-1 mt--40">
                  <span className="title">find me on</span>
                  <ul className="social-share d-flex liststyle">
                    <li className="linkedin"><a href="https://www.linkedin.com/in/michael-ayee-90a06a142">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                    </li>
                    <li className="facebook"><a href="https://web.facebook.com/michael.ayee.54">
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                    </li>
                    <li className="instagram"><a href="https://www.instagram.com/michaelayee_mykcon">
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                    </li>
                    <li className="twitter"><a href="https://twitter.com/DigitalMykcon">
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div id="home" className="rn-slider-area">
            <div className="slide slider-style-4 bg_image bg_image--1">
              <div className="container">
                <div className="row">
                  <div className="order-2 order-lg-1 col-lg-9 col-xl-7">
                    <div className="content">
                      <div className="inner mb_sm--80">
                        <h1 className="title">Hi, I’m <span>Michael Ayee</span><br/> <span
                            className="span">And I'm a Creative Strategist</span></h1>
                        <div>
                          <p className="description">I design beautiful user-centred experiences that achieve business goals. I am results-oriented with a keen eye for captivating, eye-catching creative works.</p>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-lg-12 col-xl-6 col-md-12 col-sm-12 col-12">
                          <div className="social-share-inner-left">
                            <span className="title">find me on</span>
                            <ul className="social-share d-flex liststyle">
                              <li className="linkedin"><a href="https://www.linkedin.com/in/michael-ayee-90a06a142"><i className="fa-brands fa-linkedin-in"></i></a>
                              </li>
                              <li className="facebook"><a href="https://web.facebook.com/michael.ayee.54"><i className="fa-brands fa-facebook-f"></i></a>
                              </li>
                              <li className="instagram"><a href="https://www.instagram.com/michaelayee_mykcon"><i className="fa-brands fa-instagram"></i></a>
                              </li>
                              <li className="twitter"><a href="https://twitter.com/DigitalMykcon"><i className="fa-brands fa-twitter"></i></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="about" className="rn-about-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-5">
                  <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true"
                       className="image-area">
                    <div className="thumbnail">
                      <img src="/assets/images/about/about-michael-ayee.jpg" alt="Personal Portfolio Image"/>
                    </div>
                  </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true"
                     className="col-lg-7 mt_sm--30">
                  <div className="contant">
                    <div className="section-title text-left">
                      <span className="subtitle">Introduction</span>
                      <h2 className="title">About Me</h2>
                    </div>
                    <p className="discription">
                      I'm Michael Ayee, a creative strategist from Ghana. You may have seen some of my best work across different social media platforms for clients I've worked for.
                    </p>
                    <p className="discription">
                      With more than 5 years of working experience, I have helped companies and businesses with beautiful artworks and developed plans for strategic engagement in the digital age.
                    </p>
                    <p className="discription">
                      Some of my featured clients include GCB, G-Money, CBG, Herbalife Nutrition, Nissan, Vodafone, YFM, e.TV Ghana, just to name a few.
                    </p>
                    <p className="discription">
                      I am definitely open to work on new projects and looking forward to work for you.
                    </p>
                    <a className="rn-btn mx-5" href="#" target="_blank" download><span>DOWNLOAD MY CV</span></a>
                    <a className="rn-btn mx-5" href="/assets/docs/Portfolio_Michael-Ayee.pdf" target="_blank" download><span>DOWNLOAD MY PORTFOLIO</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="skill" className="rn-skill-area rn-section-gap section-separator">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center" data-aos="fade-up" data-aos-duration="500"
                       data-aos-delay="100" data-aos-once="true">
                    <span className="subtitle">See what I can do</span>
                    <h2 className="title">My Skills</h2>
                  </div>
                  <div className="mt_md--40 mt_sm--40">
                    <ul className="skill-style-1">
                      <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="100" data-aos-once="true"
                          title="ReactJs">
                        <img src="/assets/images/icons/react.png" alt="skill"/>
                      </li>
                      <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="200" data-aos-once="true"
                          title="Figma">
                        <img src="/assets/images/icons/figma.png" alt="skill"/>
                      </li>
                      <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="300" data-aos-once="true"
                          title="XD">
                        <img src="/assets/images/icons/xd.png" alt="skill"/>
                      </li>
                      <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="400" data-aos-once="true"
                          title="PS">
                        <img src="/assets/images/icons/photoshop.png" alt="skill"/>
                      </li>
                      <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="100" data-aos-once="true"
                          title="vueJS">
                        <img src="/assets/images/icons/vuejs.png" alt="skill"/>
                      </li>
                      <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="200" data-aos-once="true"
                          title="js">
                        <img src="/assets/images/icons/js.png" alt="skill"/>
                      </li>
                      <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="300" data-aos-once="true"
                          title="angularJS">
                        <img src="/assets/images/icons/angularjs.png" alt="skill"/>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="service" className="rn-service-area rn-section-gap section-separator">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="100" data-aos-once="true"
                       className="section-title text-left">
                    <span className="subtitle">Features</span>
                    <h2 className="title">What I Do</h2>
                  </div>
                </div>
              </div>
              <div className="row row--25 mt_md--10 mt_sm--10">

                <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="100" data-aos-once="true"
                     className="col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12 mt--50 mt_md--30 mt_sm--30">
                  <div className="rn-service">
                    <div className="inner">
                      <div className="icon">
                        <i className="fa-light fa-bars fa-2x"></i>
                      </div>
                      <div className="content">
                        <h4 className="title">Graphic Designer</h4>
                        <p className="description">A good artwork speaks a mountain's worth of words. I do a great job with that.😉</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="300" data-aos-once="true"
                     className="col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12 mt--50 mt_md--30 mt_sm--30">
                  <div className="rn-service">
                    <div className="inner">
                      <div className="icon">
                        <i className="fa-light fa-book-open-cover fa-2x"></i>
                      </div>
                      <div className="content">
                        <h4 className="title">Animator</h4>
                        <p className="description">Animations are pretty much the life in any artworks. I create captivating animations to preach the message.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500" data-aos-once="true"
                     className="col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12 mt--50 mt_md--30 mt_sm--30">
                  <div className="rn-service">
                    <div className="inner">
                      <div className="icon">
                        <i className="fa-light fa-tv fa-2x"></i>
                      </div>
                      <div className="content">
                        <h4 className="title">Photographer /<br/>Videographer</h4>
                        <p className="description">Photos and videos are how memories are preserved. A good coverage is worth a life time.</p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div id="portfolio" className="rn-project-area portfolio-style-two rn-section-gap section-separator">
            <div className="container">
              <div className="row">

                <div className="col-lg-12">
                  <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="100" data-aos-once="true"
                       className="section-title text-left mb_md--25 mb_sm--25">
                    <span className="subtitle">Take a look at</span>
                    <h2 className="title">Some of My Works</h2>
                  </div>
                </div>

              </div>
              <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300" data-aos-once="true"
                   id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">

                  <div className="carousel-item active">
                    <div className="portfolio-single">
                      <div className="row direction">
                        <div className="col-lg-5">
                          <div className="inner">
                            <h5 className="title">
                              The services provice for Design
                            </h5>
                            <p className="discription">
                              Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, quidem
                              dignissimos.
                              Perspiciatis fuga soluta officiis eligendi labore, omnis ut velit vitae
                              suscipit
                              alias cumque temporibus.
                            </p>
                            <div className="ft-area">
                              <div className="feature-wrapper">
                                <div className="single-feature">
                                  <i className="fa-light fa-check"></i>
                                  <p>Responsive design</p>
                                </div>
                                <div className="single-feature">
                                  <i className="fa-light fa-check"></i>
                                  <p>Quiz builder</p>
                                </div>
                                <div className="single-feature">
                                  <i className="fa-light fa-check"></i>
                                  <p>Certification</p>
                                </div>
                                <div className="single-feature">
                                  <i className="fa-light fa-check"></i>
                                  <p>Stats & reports</p>
                                </div>
                                <div className="single-feature">
                                  <i className="fa-light fa-check"></i>
                                  <p>Retina ready</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7 col-xl-7">
                          <div className="thumbnail">
                            <img src="/assets/images/portfolio/portfolio-04.jpg" alt="Personal Portfolio Image"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="portfolio-single">
                      <div className="row direction">
                        <div className="col-lg-5">
                          <div className="inner">
                            <h5 className="title">
                              The services provice for Development
                            </h5>
                            <p className="discription">
                              Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, quidem
                              dignissimos.
                              Perspiciatis fuga soluta officiis eligendi labore, omnis ut velit vitae
                              suscipit
                              alias cumque temporibus.
                            </p>
                            <div className="ft-area">
                              <div className="feature-wrapper">
                                <div className="single-feature">
                                  <i className="fa-light fa-check"></i>
                                  <p>Divice maintain</p>
                                </div>
                                <div className="single-feature">
                                  <i className="fa-light fa-check"></i>
                                  <p>Theme builder</p>
                                </div>
                                <div className="single-feature">
                                  <i className="fa-light fa-check"></i>
                                  <p>Certification</p>
                                </div>
                                <div className="single-feature">
                                  <i className="fa-light fa-check"></i>
                                  <p>Stats & reports</p>
                                </div>
                                <div className="single-feature">
                                  <i className="fa-light fa-check"></i>
                                  <p>Retina ready</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7 col-xl-7">
                          <div className="thumbnail">
                            <img src="/assets/images/portfolio/portfolio-01.jpg" alt="Personal Portfolio Image"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="portfolio-single">
                      <div className="row direction">
                        <div className="col-lg-5">
                          <div className="inner">
                            <h5 className="title">
                              The services provice for Android user
                            </h5>
                            <p className="discription">
                              Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, quidem
                              dignissimos.
                              Perspiciatis fuga soluta officiis eligendi labore, omnis ut velit vitae
                              suscipit
                              alias cumque temporibus.
                            </p>
                            <div className="ft-area">
                              <div className="feature-wrapper">
                                <div className="single-feature">
                                  <i className="fa-light fa-check"></i>
                                  <p>Customer satisfaction</p>
                                </div>
                                <div className="single-feature">
                                  <i className="fa-light fa-check"></i>
                                  <p>Quiz builder</p>
                                </div>
                                <div className="single-feature">
                                  <i className="fa-light fa-check"></i>
                                  <p>Certification</p>
                                </div>
                                <div className="single-feature">
                                  <i className="fa-light fa-check"></i>
                                  <p>Stats & reports</p>
                                </div>
                                <div className="single-feature">
                                  <i className="fa-light fa-check"></i>
                                  <p>Football & reports</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7 col-xl-7">
                          <div className="thumbnail">
                            <img src="/assets/images/portfolio/portfolio-05.jpg" alt="Personal Portfolio Image"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button className="carousel-control-prev" role="button" data-bs-target="#carouselExampleControls"
                        data-bs-slide="prev">
                  <i className="fa-light fa-chevron-left"></i>
                </button>
                <button className="carousel-control-next" role="button" data-bs-target="#carouselExampleControls"
                        data-bs-slide="next">
                  <i className="fa-light fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>

          <div id="client" className="rn-client-area rn-client-style-2 rn-section-gap section-separator">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="100" data-aos-once="true"
                       className="section-title text-center">
                    <span className="subtitle">A List of</span>
                    <h2 className="title">Our Trusted Clients</h2>
                  </div>
                  <div className="skill-style-1">
                    <div className="client-card">

                      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="100" data-aos-once="true"
                           className="main-content">
                        <div className="inner text-center">
                          <div className="thumbnail">
                            <a href="#"><img src="/assets/images/clients/g-money.png" alt="Client-image"/></a>
                          </div>
                          <div className="seperator"></div>
                          <div className="client-name"><span><a href="#">G-Money</a></span>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="300" data-aos-once="true"
                           className="main-content">
                        <div className="inner text-center">
                          <div className="thumbnail">
                            <a href="#"><img src="/assets/images/clients/cbg.png" alt="Client-image"/></a>
                          </div>
                          <div className="seperator"></div>
                          <div className="client-name"><span><a href="#">CBG</a></span>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="100" data-aos-once="true"
                           className="main-content">
                        <div className="inner text-center">
                          <div className="thumbnail">
                            <a href="#"><img src="/assets/images/clients/xplore-ghana.png" alt="Client-image"/></a>
                          </div>
                          <div className="seperator"></div>
                          <div className="client-name"><span><a href="#">Xplore Ghana</a></span>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="300" data-aos-once="true"
                           className="main-content">
                        <div className="inner text-center">
                          <div className="thumbnail">
                            <a href="#"><img src="/assets/images/clients/yfm.png" alt="Client-image"/></a>
                          </div>
                          <div className="seperator"></div>
                          <div className="client-name"><span><a href="#">YFM</a></span>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500" data-aos-once="true"
                           className="main-content">
                        <div className="inner text-center">
                          <div className="thumbnail">
                            <a href="#"><img src="/assets/images/clients/sogakope-resort.png" alt="Client-image"/></a>
                          </div>
                          <div className="seperator"></div>
                          <div className="client-name"><span><a href="#">Sogakope Beach Resort</a></span>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500" data-aos-once="true"
                           className="main-content">
                        <div className="inner text-center">
                          <div className="thumbnail">
                            <a href="#"><img src="/assets/images/clients/axim-beach-resort.png" alt="Client-image"/></a>
                          </div>
                          <div className="seperator"></div>
                          <div className="client-name"><span><a href="#">Axim Beach Resort</a></span>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500" data-aos-once="true"
                           className="main-content">
                        <div className="inner text-center">
                          <div className="thumbnail">
                            <a href="#"><img src="/assets/images/clients/japan-motors.png" alt="Client-image"/></a>
                          </div>
                          <div className="seperator"></div>
                          <div className="client-name"><span><a href="#">Japan Motors</a></span>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500" data-aos-once="true"
                           className="main-content">
                        <div className="inner text-center">
                          <div className="thumbnail">
                            <a href="#"><img src="/assets/images/clients/visit-ghana.png" alt="Client-image"/></a>
                          </div>
                          <div className="seperator"></div>
                          <div className="client-name"><span><a href="#">Visit Ghana</a></span>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500" data-aos-once="true"
                           className="main-content">
                        <div className="inner text-center">
                          <div className="thumbnail">
                            <a href="#"><img src="/assets/images/clients/herbalife.png" alt="Client-image"/></a>
                          </div>
                          <div className="seperator"></div>
                          <div className="client-name"><span><a href="#">HerbaLife</a></span>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500" data-aos-once="true"
                           className="main-content">
                        <div className="inner text-center">
                          <div className="thumbnail">
                            <a href="#"><img src="/assets/images/clients/motac.png" alt="Client-image"/></a>
                          </div>
                          <div className="seperator"></div>
                          <div className="client-name"><span><a href="#">MOTAC</a></span>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500" data-aos-once="true"
                           className="main-content">
                        <div className="inner text-center">
                          <div className="thumbnail">
                            <a href="#"><img src="/assets/images/clients/smile.png" alt="Client-image"/></a>
                          </div>
                          <div className="seperator"></div>
                          <div className="client-name"><span><a href="#">SMILE</a></span>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500" data-aos-once="true"
                           className="main-content">
                        <div className="inner text-center">
                          <div className="thumbnail">
                            <a href="#"><img src="/assets/images/clients/abl.png" alt="Client-image"/></a>
                          </div>
                          <div className="seperator"></div>
                          <div className="client-name"><span><a href="#">Accra Brewery Limited</a></span>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500" data-aos-once="true"
                           className="main-content">
                        <div className="inner text-center">
                          <div className="thumbnail">
                            <a href="#"><img src="/assets/images/clients/baileys.png" alt="Client-image"/></a>
                          </div>
                          <div className="seperator"></div>
                          <div className="client-name"><span><a href="#">Baileys</a></span>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500" data-aos-once="true"
                           className="main-content">
                        <div className="inner text-center">
                          <div className="thumbnail">
                            <a href="#"><img src="/assets/images/clients/johnny-walker.png" alt="Client-image"/></a>
                          </div>
                          <div className="seperator"></div>
                          <div className="client-name"><span><a href="#">Johnny Walker</a></span>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500" data-aos-once="true"
                           className="main-content">
                        <div className="inner text-center">
                          <div className="thumbnail">
                            <a href="#"><img src="/assets/images/clients/whoopro.png" alt="Client-image"/></a>
                          </div>
                          <div className="seperator"></div>
                          <div className="client-name"><span><a href="#">Whoopro</a></span>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500" data-aos-once="true"
                           className="main-content">
                        <div className="inner text-center">
                          <div className="thumbnail">
                            <a href="#"><img src="/assets/images/clients/kaiptc.png" alt="Client-image"/></a>
                          </div>
                          <div className="seperator"></div>
                          <div className="client-name"><span><a href="#">KAIPTC</a></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
              className="rn-testimonial-area testimonial-style-2 testimonial-with-carousel rn-section-gapTop pb--80 pb_md--50 section-separator"
              id="testimonial">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="100" data-aos-once="true"
                       className="section-title text-center mb--20 mb_md--20 mb_sm--20">
                    <span className="subtitle">What Clients Say</span>
                    <h2 className="title">Testimonials</h2>
                  </div>
                </div>
              </div>

              <div className="testimonial-activation-item-3 slick-arrow-style-one mb--60" data-aos="fade-up"
                   data-aos-duration="500" data-aos-delay="300" data-aos-once="true">

                <div className="row">
                  <div className="rn-testimonial col-md-3">
                    <div className="testimonial-inner">
                      <div className="testimonial-header">
                        <div className="thumbnail">
                          <img src="/assets/images/client/testimonial-2.jpg" alt="client-image"/>
                        </div>
                        <h5 className="ts-header">
                          <span className="text-color-primary">@alamin</span> Engineer
                        </h5>
                      </div>
                      <div className="testimonial-body">
                        <p className="discription">@mr_rasel Ypur plaser well for your template
                          from
                          <span>@mr_faruk </span> There is no is best issueusing it too. It'll really
                          speed up the design and
                          development process :).
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="rn-testimonial col-md-3">
                    <div className="testimonial-inner">
                      <div className="testimonial-header">
                        <div className="thumbnail">
                          <img src="/assets/images/client/testimonial-2.jpg" alt="client-image"/>
                        </div>
                        <h5 className="ts-header">
                          <span className="text-color-primary">@mr_rasel</span> Engineer
                        </h5>
                      </div>
                      <div className="testimonial-body">
                        <p className="discription">@Covis_jackson Much plaser well for your template
                          from
                          <span>@tabriz </span>! The team is best using it too. It'll really
                          speed up the design and
                          development process ==);.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="rn-testimonial col-md-3">
                    <div className="testimonial-inner">
                      <div className="testimonial-header">
                        <div className="thumbnail">
                          <img src="/assets/images/client/testimonial-2.jpg" alt="client-image"/>
                        </div>
                        <h5 className="ts-header">
                          <span className="text-color-primary">@Kabir</span> Engineer
                        </h5>
                      </div>
                      <div className="testimonial-body">
                        <p className="discription">@jack_danials thank you as well for your template
                          from
                          <span>@Alamin_Orko </span>! The team is using it too. It'll really
                          speed up the design and
                          development process 💨.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="rn-testimonial col-md-3">
                    <div className="testimonial-inner">
                      <div className="testimonial-header">
                        <div className="thumbnail">
                          <img src="/assets/images/client/testimonial-2.jpg" alt="client-image"/>
                        </div>
                        <h5 className="ts-header">
                          <span className="text-color-primary">@Tuhin</span> Engineer
                        </h5>
                      </div>
                      <div className="testimonial-body">
                        <p className="discription">@jack_danials thank you as well for your template
                          from
                          <span>@Alamin_Orko </span>! The team is using it too. It'll really
                          speed up the design and
                          development process 🏃.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Blog/>
          <div className="rn-contact-area rn-section-gap section-separator" id="contacts">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center">
                    <span className="subtitle">Contact</span>
                    <h2 className="title">Contact Me</h2>
                  </div>
                </div>
              </div>

              <div className="row mt--50 mt_md--40 mt_sm--40 mt-contact-sm">
                <div className="col-lg-5">
                  <div className="contact-about-area">
                    <div className="thumbnail">
                      <img src="/assets/images/contact/contact1.png" alt="contact-img"/>
                    </div>
                    <div className="title-area">
                      <h4 className="title">Michael Ayee</h4>
                      <span>Creative Strategist</span>
                    </div>
                    <div className="description">
                      <p>Let me work for you. Reach out to me via my socials, email or phone.</p>
                      <span className="phone">Phone: <a href="tel:+233501429263">+233 (0) 50 142 9263</a></span>
                      <span className="mail">Email: <a href="mailto:michaelayee8@gmail.com">michaelayee8@gmail.com</a></span>
                    </div>
                    <div className="social-area">
                      <div className="name">MY SOCIALS</div>
                      <div className="social-icone">
                        <a href="https://www.linkedin.com/in/michael-ayee-90a06a142"><i className="fa-brands fa-linkedin-in"></i></a>
                        <a href="https://web.facebook.com/michael.ayee.54"><i className="fa-brands fa-facebook-f"></i></a>
                        <a href="https://www.instagram.com/michaelayee_mykcon"><i className="fa-brands fa-instagram"></i></a>
                        <a href="https://twitter.com/DigitalMykcon"><i className="fa-brands fa-twitter"/></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-aos-delay="600" className="col-lg-7 contact-input">
                  <div className="contact-form-wrapper">
                    <div className="introduce">

                      <form className="rnt-contact-form rwt-dynamic-form row" id="contact-form" onSubmit={(e)=>{
                        e.preventDefault()
                        contactMe()
                      }}>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="contact-name">Your Name</label>
                            <input className="form-control form-control-lg" name="contact-name" id="contact-name"
                                   type="text" value={name} onChange={(e)=>{setName(e.target.value)}} required={true}/>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="contact-phone">Phone Number</label>
                            <input className="form-control" name="contact-phone" id="contact-phone" type="text" value={phone} onChange={(e)=>{setPhone(e.target.value)}}/>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <label htmlFor="contact-email">Email</label>
                            <input className="form-control form-control-sm" id="contact-email" name="contact-email"
                                   type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} required={true}/>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <label htmlFor="subject">subject</label>
                            <input className="form-control form-control-sm" id="subject" name="subject" type="text" value={subject} onChange={(e)=>{setSubject(e.target.value)}} required={true}/>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <label htmlFor="contact-message">Your Message</label>
                            <textarea name="contact-message" id="contact-message" cols="30" rows="10" value={message} onChange={(e)=>{setMessage(e.target.value)}} required={true}/>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button name="submit" type="submit" id="submit" className="rn-btn" disabled={sendingMsg}>
                            {sendingMsg && <i className="fa-solid fa-loader fa-spin fa-2x"/>}
                            {!sendingMsg &&
                              <>
                                <span>SEND MESSAGE</span>
                                <i className="fa-regular fa-arrow-right"></i>
                              </>
                            }
                          </button>
                          {respMsg && <p className="py-3 text-color-primary">{respMsg}</p>}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="backto-top">
            <div>
              <i className="fa-light fa-arrow-up"></i>
            </div>
          </div>
        </main>

        <div id="footer">
          {/*<div className="container pb--80 pb_sm--40 plr_sm--20">
            <div className="row">
              <div className="col-xl-3 col-12 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="logo-thumbnail">
                  <a href="#"><img src="/assets/images/logo/logo-dark.png" alt="logo-image"/></a>
                </div>
                <div className="social-icone-wrapper">
                  <ul className="social-share d-flex liststyle">
                    <li className="linkedin"><a href="https://www.linkedin.com/in/michael-ayee-90a06a142"><i className="fa-brands fa-linkedin-in"></i></a>
                    </li>
                    <li className="facebook"><a href="https://web.facebook.com/michael.ayee.54"><i className="fa-brands fa-facebook-f"></i></a>
                    </li>
                    <li className="instagram"><a href="https://www.instagram.com/michaelayee_mykcon"><i className="fa-brands fa-instagram"></i></a>
                    </li>
                    <li className="twitter"><a href="https://twitter.com/DigitalMykcon"><i className="fa-brands fa-twitter"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sl-3 col-12 mt_sm--20 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="menu-wrapper">
                  <div className="menu-title">
                    <h6>product</h6>
                  </div>
                  <ul className="menu-footer">
                    <li><a href="#">Database</a></li>
                    <li><a href="#">Authentication</a></li>
                    <li><a href="#">Storage</a></li>
                    <li><a href="#">Support</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-sl-3 col-12 mt_sm--20 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="menu-wrapper">
                  <div className="menu-title">
                    <h6>Resources</h6>
                  </div>
                  <ul className="menu-footer">
                    <li><a href="#">Authentication</a></li>
                    <li><a href="#">System Status</a></li>
                    <li><a href="#">Terms of service</a></li>
                    <li><a href="#">over right</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-sl-3 col-12 mt_sm--20 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="menu-wrapper">
                  <div className="menu-title">
                    <h6>Developers</h6>
                  </div>
                  <ul className="menu-footer">
                    <li><a href="#">Documentation</a></li>
                    <li><a href="#">Authentication</a></li>
                    <li><a href="#">API Reference</a></li>
                    <li><a href="#">Support</a></li>
                    <li><a href="#">Open Source</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>*/}
          <div className="copyright text-center ptb--40 section-separator">
            <p className="description">© {new Date().getFullYear()}. All rights reserved. Designed with ❤️ by <a
                href="https://kraftkey.com" className="text-color-primary" target="_blank">KraftKey</a></p>
          </div>
        </div>
      </>
  );
}

export default App;
