import {useEffect, useState} from "react";
import {apiUrl} from "../helpers";

const Blog = () => {
    const [posts,setPosts] = useState([])
    const [loading,setLoading] = useState(true)
    const [singlePost,setSinglePost] = useState({})
    const [singleLoading,setSingleLoading] = useState(false)

    useEffect(()=>{
        fetch(`${apiUrl}/posts`)
            .then(posts => posts.json())
            .then(res => {
                setPosts(res)
                setLoading(false)
            })
    },[])

    function getSingle(id){
        setSingleLoading(true)
        fetch(`${apiUrl}/posts/${id}`)
            .then(post => post.json())
            .then(resp => {
                setSinglePost(resp)
                setSingleLoading(false)
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <>
        <div className="rn-blog-area rn-section-gap section-separator" id="blog">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="100" data-aos-once="true"
                             className="section-title text-center">
                            <span className="subtitle">Some thoughts I share in writing</span>
                            <h2 className="title">My Blog</h2>
                        </div>
                    </div>
                </div>
                <div className="row row--25 mt--30 mt_md--10 mt_sm--10">
                    {loading && <p className="text-center">Loading...</p>}
                    {!loading &&
                        <>
                            {posts.length === 0 &&
                                <>
                                    <h4 className="text-secondary mt-4 text-center">There are no blog posts at the moment</h4>
                                    <p className="text-center">Posts shall be listed here when available</p>
                                </>
                            }
                            {posts.length > 0 && posts.map((post,index) => {
                                if (index > 8) return null
                                return (
                                <div key={post.id} data-aos="fade-up" data-aos-duration="500" data-aos-delay="100" data-aos-once="true"
                                     className="col-lg-6 col-xl-4 mt--30 col-md-6 col-sm-12 col-12 mt--30">
                                    <div className="rn-blog" data-bs-toggle="modal" data-bs-target="#postContentModal" onClick={()=>{getSingle(post.id)}}>
                                        <div className="inner">
                                            <div className="thumbnail d-flex justify-content-center">
                                                <img className="rounded-3" src={post.x_featured_media_medium} alt={post.title.rendered}/>
                                            </div>
                                            <div className="content">
                                                <div className="category-info">
                                                    <div className="category-list">
                                                        <a href="javascript:void(0)">{post.x_categories}</a>
                                                    </div>
                                                    <div className="meta">
                                                        <span><i className="feather-clock"></i> {post.x_date}</span>
                                                    </div>
                                                </div>
                                                <h4 className="title"><a href="javascript:void(0)">{post.title.rendered}
                                                    <i className="feather-arrow-up-right"></i></a></h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )})}
                        </>
                    }
                </div>
            </div>
        </div>

    {/*post modal*/}
            <div className="modal fade" id="postContentModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-news" role="document">
                    <div className="modal-content">

                        <div className="modal-header">
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="fa-light fa-xmark"></i></span>
                            </button>
                        </div>


                        <div className="modal-body">
                            {singleLoading &&
                                <div className="text-center">
                                    <i className="fa-solid fa-loader fa-2x fa-spin text-color-primary"/>
                                    <p className="text-center">Loading blog post...</p>
                                </div>
                            }
                            {!singleLoading &&
                                <>
                                {Object.keys(singlePost).length > 0 &&
                                    <>
                                        <img src={singlePost.x_featured_media_large} alt={singlePost.title.rendered} className="img-fluid modal-feat-img"/>
                                        <div className="news-details">
                                            <span className="date">{singlePost.x_date}</span>
                                            <h2 className="title">{singlePost.title.rendered}</h2>
                                            <div dangerouslySetInnerHTML={{__html:singlePost.content.rendered}}/>
                                        </div>

                                        <div className="comment-inner">
                                            <h3 className="title mb--40 mt--50">Leave a Reply</h3>
                                            <form action="#">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-12 col-12">
                                                        <div className="rnform-group"><input type="text" placeholder="Name"/>
                                                        </div>
                                                        <div className="rnform-group"><input type="email" placeholder="Email"/>
                                                        </div>
                                                        <div className="rnform-group"><input type="text" placeholder="Website"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12 col-12">
                                                        <div className="rnform-group">
                                                            <textarea placeholder="Comment"></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <a className="rn-btn" href="#"><span>SUBMIT NOW</span></a>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </>
                                }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
    </>
    )
}

export default Blog